import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import {Helmet} from "react-helmet";
import Downloads from "../components/downloads/downloads";

import SocialImage from '../images/social/LogoNeu.jpg';

export default class LogoDownload extends React.Component {

    render() {
        return (
            <>
                <Helmet htmlAttributes={{"lang": "de"}}>
                    {/*Primary Meta Tags*/}
                    <title>Kronschl&auml;ger Reisen — Logo Download</title>
                    <meta name="title" content="Kronschl&auml;ger Reisen — Logo Download"/>
                    <meta name="description" content="Kurze Beschreibung, 170 Zeichen"/>

                    {/*Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.kronschlaeger-reisen.at"/>
                    <meta property="og:title" content="Kronschl&auml;ger Reisen — Logo Download"/>
                    <meta property="og:description" content="Same as above"/>
                    <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

                    {/* Google Bot */}
                    <meta name="robots" content="noindex,follow"/>
                </Helmet>
                <Header/>
                <Downloads />
                <Footer/>
            </>
        );
    }
}