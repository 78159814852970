import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from 'gatsby-image';
import * as styles from "./downloads.module.css";

export default function Downloads() {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "logos"}}) {
                nodes {
                    childImageSharp {
                        id
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                            originalImg
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-sm-12"}>
                    <div className={styles.sectionHeaderContainer}>
                        <h2>Firmen&shy;logos Down&shy;load</h2>
                        <p>Sie ben&ouml;tigen die Logos f&uuml;r Werbung - klicken Sie auf das
                            gew&uuml;nschte Logo und speichern es.</p>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                {data.allFile.nodes.map((node) => {
                    return (
                        <div key={node.childImageSharp.id} className={"col-lg-4 col-md-6 col-sm-12"}>
                            <a download={true} href={node.childImageSharp.fluid.originalImg}>
                                <div className={styles.logoContainer}>
                                    <Img fluid={node.childImageSharp.fluid}/>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}